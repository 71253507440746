import React from "react";
import Layout from "./src/components/Layout";
import "./global-style.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./src/i18n";

export function wrapPageElement({ element, props }) {
  return (
    <I18nextProvider i18n={i18n}>
      <Layout {...props}>{element}</Layout>
    </I18nextProvider>
  );
}
