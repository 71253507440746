exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-dental-care-index-tsx": () => import("./../../../src/pages/services/dental-care/index.tsx" /* webpackChunkName: "component---src-pages-services-dental-care-index-tsx" */),
  "component---src-pages-services-dental-implants-index-tsx": () => import("./../../../src/pages/services/dental-implants/index.tsx" /* webpackChunkName: "component---src-pages-services-dental-implants-index-tsx" */),
  "component---src-pages-services-dental-surgery-index-tsx": () => import("./../../../src/pages/services/dental-surgery/index.tsx" /* webpackChunkName: "component---src-pages-services-dental-surgery-index-tsx" */),
  "component---src-pages-services-emax-veneers-index-tsx": () => import("./../../../src/pages/services/emax-veneers/index.tsx" /* webpackChunkName: "component---src-pages-services-emax-veneers-index-tsx" */),
  "component---src-pages-services-orthodontics-index-tsx": () => import("./../../../src/pages/services/orthodontics/index.tsx" /* webpackChunkName: "component---src-pages-services-orthodontics-index-tsx" */),
  "component---src-pages-services-pedodontia-index-tsx": () => import("./../../../src/pages/services/pedodontia/index.tsx" /* webpackChunkName: "component---src-pages-services-pedodontia-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */)
}

