import React from "react";
import "./style.css";
import { TypographyHeadingComponentProps } from "./types";

const H2: React.FC<TypographyHeadingComponentProps> = ({ children, style }) => {
  return (
    <h2 className="h2-wrapper" style={style}>
      {children}
    </h2>
  );
};

export default H2;
