import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  return (
    <select
      style={{
        backgroundColor: "transparent",
        outline: "none",
        padding: "5px 10px",
        borderRadius: "5px",
        fontSize: "16px",
        borderColor: "black",
        marginTop: 10,
      }}
      onChange={handleLanguageChange}
      defaultValue={i18n.language}
    >
      <option value="en" label="english">
        English
      </option>
      <option value="sq" label="albanian">
        Albanian
      </option>
      <option value="it" label="italian">
        Italian
      </option>
    </select>
  );
};

export default LanguageSelect;
