import React from "react";
import "./style.css";
import { TypographyHeadingComponentProps } from "./types";

const P: React.FC<TypographyHeadingComponentProps> = ({ children, style }) => {
  return (
    <p className="p-wrapper" style={style}>
      {children}
    </p>
  );
};

export default P;
