import React, { ReactNode, useState } from "react";
import Stack from "../Stack";
import { H4, P } from "../Typography";
import { IoIosArrowForward } from "react-icons/io";
import "./style.css";

interface AccordionComponentProps {
  title: string;
  content: ReactNode;
  startIcon: ReactNode;
}

const Accordion: React.FC<AccordionComponentProps> = ({
  title,
  startIcon,
  content,
}) => {
  const [expand, setExpand] = useState(false);

  return (
    <div className="accordion-wrapper">
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => setExpand(!expand)}
        gap={1}
      >
        <Stack flexDirection="row" gap={20} alignItems="center">
          {startIcon}
          <H4 style={{ textTransform: "capitalize" }}>{title}</H4>
        </Stack>
        <IoIosArrowForward style={{ fontSize: "20px", flexShrink: 0 }} />
      </Stack>
      <div
        className={`accordion-content accordion-state-${
          expand ? "open" : "closed"
        }`}
      >
        <P>{content}</P>
      </div>
    </div>
  );
};

export default Accordion;
