import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const Header = () => {
  const { t } = useTranslation();
  const [openNavbar, setOpenNavbar] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      const navbar = document?.getElementById("navbar");
      if (!navbar?.contains(event.target)) {
        setOpenNavbar(false);
      }
    };
    document?.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document?.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={
        openNavbar
          ? "header-container header-container-open"
          : "header-container"
      }
      id="navbar"
    >
      <div className="header-links-wrapper">
        <NavBarLink
          label={t("home")}
          href="/"
          onClick={() => setOpenNavbar(false)}
        />
        <NavBarLink
          label={t("about_us")}
          href="/about-us"
          onClick={() => setOpenNavbar(false)}
        />
        <div
          className="treatments-link-item"
          onClick={() => setOpenNavbar(true)}
        >
          {t("treatments")}
        </div>
      </div>
      <div className="header-treatments-wrapper">
        <NavBarLink
          label={t("dental_surgery")}
          href="/services/dental-surgery"
          onClick={() => setOpenNavbar(false)}
        />
        <NavBarLink
          label={t("dental_implants")}
          href="/services/dental-implants"
          onClick={() => setOpenNavbar(false)}
        />
        <NavBarLink
          label={t("basic_dental_care")}
          href="/services/dental-care"
          onClick={() => setOpenNavbar(false)}
        />
        <NavBarLink
          label={t("emax_veneers")}
          href="/services/emax-veneers"
          onClick={() => setOpenNavbar(false)}
        />
        <NavBarLink
          label={t("orthodontics")}
          href="/services/orthodontics"
          onClick={() => setOpenNavbar(false)}
        />
        <NavBarLink
          label={t("pedodontia")}
          href="/services/pedodontia"
          onClick={() => setOpenNavbar(false)}
        />
      </div>
    </div>
  );
};

const NavBarLink: React.FC<{ label: string; href: string; onClick?: any }> = ({
  label,
  href,
  onClick,
}) => {
  return (
    <Link
      style={{
        textDecoration: "none",
        color: "var(--primary-color)",
        pointerEvents: "auto",
        textTransform: "capitalize",
      }}
      to={href}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {label}
    </Link>
  );
};

export default Header;
