import React from "react";
import "./style.css";
import { TypographyHeadingComponentProps } from "./types";

const H3: React.FC<TypographyHeadingComponentProps> = ({ children, style }) => {
  return (
    <h3 className="h3-wrapper" style={style}>
      {children}
    </h3>
  );
};

export default H3;
