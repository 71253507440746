import React, { CSSProperties, HTMLProps, ReactNode } from "react";

const SpaceSizes = {
  XXS: 4,
  XS: 4,
  S: 8,
  M: 16,
  L: 24,
  XL: 48,
  XXL: 64,
  NONE: 0,
};

interface StackComponentProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  style?: CSSProperties;
  flexDirection?: "row" | "column";
  gap?: "XS" | "S" | "M" | "L" | "XL" | "XXL" | "NONE" | number;
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "stretch";
}

const Stack: React.FC<StackComponentProps> = ({
  children,
  style = {},
  flexDirection,
  gap,
  justifyContent,
  alignItems,
  alignContent,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      style={{
        display: "flex",
        flexDirection: !!flexDirection ? flexDirection : "column",
        gap: typeof gap === "string" ? SpaceSizes[gap] : gap ? gap : undefined,
        justifyContent: !!justifyContent ? justifyContent : "flex-start",
        alignItems: !!alignItems ? alignItems : "flex-start",
        alignContent: !!alignContent ? alignContent : "center",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
