import React, { ButtonHTMLAttributes, CSSProperties } from "react";
import { IoIosArrowForward } from "react-icons/io";
import Stack from "../Stack";
import "./style.css";

interface ButtonComponentProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  style?: CSSProperties;
  variant?: "contained" | "outlined" | "text";
  size?: "S" | "M" | "L";
  color?: "primary" | "secondary";
  icon?: any;
}

const Button: React.FC<ButtonComponentProps> = ({
  children,
  style = {},
  variant = "text",
  size = "M",
  color = "primary",
  icon,
  ...otherProps
}) => {
  return (
    <button
      type="button"
      aria-label="Click me"
      className={`button-wrapper button-size-${size}`}
      style={{
        backgroundColor:
          variant === "contained"
            ? `var(--button-${color}-color)`
            : "transparent",
        color:
          variant === "contained"
            ? `var(--button-text-${color}-color)`
            : `var(--button-${color}-color)`,
        border:
          variant === "text"
            ? "none"
            : `1px solid var(--button-${color}-color)`,
        ...style,
      }}
      {...otherProps}
    >
      <div className="button-content-wrapper">
        <Stack flexDirection="row" gap="S" alignItems="center">
          {icon}
          <span className="button-text-wrapper">{children}</span>
        </Stack>
        <span className="button-icon-wrapper">
          <IoIosArrowForward />
        </span>
      </div>
    </button>
  );
};

export default Button;
