import React, { ReactNode } from "react";
import { LanguageSelect, Stack } from "../common";
import { H2, P } from "../common/Typography";
import "./style.css";
import { FaMapMarked } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer-container">
      <Stack flexDirection="column" gap="XXL">
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          gap="L"
          style={{ width: "100%", flexWrap: "wrap", color: "#333333" }}
        >
          <Stack flexDirection="column" gap="XS" style={{ minWidth: 350 }}>
            <H2>Orbis Dental Care</H2>
            <TextWithIcon>
              <FaMapMarked style={{ fontSize: 20 }} /> RRUGA 2 PRILLI, 1054 -
              Bathore, Kamëz
            </TextWithIcon>
            <TextWithIcon>
              <IoCall style={{ fontSize: 20 }} />
              (+355) 685073933
            </TextWithIcon>
            <TextWithIcon>
              <IoCall style={{ fontSize: 20 }} />
              (+355) 692054021
            </TextWithIcon>
            <TextWithIcon>
              <MdEmail style={{ fontSize: 20 }} />
              info@orbisdentalcare.com
            </TextWithIcon>
            <LanguageSelect />
          </Stack>
          <Stack flexDirection="column" gap="XS" style={{ minWidth: 130 }}>
            <H2 style={{ textTransform: "capitalize" }}>{t("services")}</H2>
            <NavLink to={"/services/dental-care"}>
              <P>{t("basic_dental_care")}</P>
            </NavLink>
            <NavLink to={"/services/dental-surgery"}>
              <P>{t("dental_surgery")}</P>
            </NavLink>
            <NavLink to={"/services/orthodontics"}>
              <P>{t("orthodontics")}</P>
            </NavLink>
            <NavLink to={"/services/dental-implants"}>
              <P>{t("dental_implants")}</P>
            </NavLink>
            <NavLink to={"/services/emax-veneers"}>
              <P>{t("emax_veneers")}</P>
            </NavLink>
            <NavLink to={"/services/pedodontia"}>
              <P>{t("pedodontia")}</P>
            </NavLink>
          </Stack>
          <Stack flexDirection="column" gap="XS" style={{ minWidth: 160 }}>
            <H2 style={{ textTransform: "capitalize" }}>{t("our_clinic")}</H2>
            <NavLink to="/">
              <P>{t("home")}</P>
            </NavLink>
            <NavLink to="/about-us">
              <P>{t("about_us")}</P>
            </NavLink>
            <NavLink to="/contact-us">
              <P>{t("contact")}</P>
            </NavLink>
          </Stack>
          <Stack flexDirection="column" gap="XS" style={{ minWidth: 155 }}>
            <H2 style={{ textTransform: "capitalize" }}>{t("follow_us")}</H2>
            <a
              href="https://www.facebook.com/profile.php?id=100063568616225"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <TextWithIcon>
                <FaFacebookSquare style={{ fontSize: 20 }} />
                Facebook
              </TextWithIcon>
            </a>
            <a
              href="https://www.instagram.com/orbis_dentalcare"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <TextWithIcon>
                <FaInstagramSquare style={{ fontSize: 20 }} />
                Instagram
              </TextWithIcon>
            </a>
          </Stack>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="flex-start"
          gap="M"
          style={{ flexWrap: "wrap" }}
        >
          <P>
            {t("copyright")} © 2023 Orbis Dental Care.{" "}
            {t("all_rights_reserved")}.
          </P>
          <NavLink to="/terms-and-conditions">
            <P>{t("terms_of_use")}</P>
          </NavLink>
          <P>Albania</P>
        </Stack>
      </Stack>
    </footer>
  );
};

export default Footer;

const TextWithIcon: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <P
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "10px",
      }}
    >
      {children}
    </P>
  );
};

const NavLink: React.FC<{ children: ReactNode; to: string }> = ({
  to,
  children,
}) => {
  return (
    <Link
      to={to}
      style={{ textDecoration: "none", textTransform: "capitalize" }}
    >
      {children}
    </Link>
  );
};
