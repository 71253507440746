import React from "react";
import { FaWhatsappSquare } from "react-icons/fa";

const WhatsAppLink = () => {
  const phoneNumber = "+355685073933";
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    ""
  )}`;
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        bottom: "40px",
        right: "40px",
      }}
    >
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        <FaWhatsappSquare style={{ fontSize: 56, color: "#24cc62" }} />
      </a>
    </div>
  );
};

export default WhatsAppLink;
