import React from "react";
import "./style.css";
import { TypographyHeadingComponentProps } from "./types";

const H4: React.FC<TypographyHeadingComponentProps> = ({ children, style }) => {
  return (
    <h4 className="h4-wrapper" style={style}>
      {children}
    </h4>
  );
};

export default H4;
