import React from "react";
import { LanguageWithFlagsSelect } from "../common";
import Footer from "../Footer";
import Header from "../Header";
import "../theme.css";
import WhatsAppLink from "../WhatsAppLink";

const Layout: React.FC<{ children: any }> = ({ children }) => {
  return (
    <div style={{ position: "relative" }}>
      <LanguageWithFlagsSelect />
      <div
        style={{ position: "relative", zIndex: 10, backgroundColor: "white" }}
      >
        <Header />
        {children}
      </div>
      <Footer />
      <WhatsAppLink />
    </div>
  );
};

export default Layout;
